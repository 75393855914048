import React, { useState, useEffect } from "react";

import "./About.scss";

const About = () => {
    return (
        <section id="about" className="page-section">
            <div className="container position-relative text-center">
                <h1 className="title-verify">WHY TAPEME?</h1>
                <div className="text-middle">Today's world allows several possibilities for meeting. One of the forms is online communication and this is undoubtedly a much easier way. And why not to do it in a more funny way?</div>
                <div className="text-middle">Are you tired of looking for a relationship that is based only on perfect looks? Do you want to find your soulmate or new friends? Do you want to have fun? If YES, this new dating app is made just for you!</div>
                <div className="text-middle">We would like to introduce a new free app called TapeMe. The app brings a new online communication that is based on mutual sympathy using voice.</div>
                <div className="text-middle">WE are and will be your partner on your new journey through the world of voice, supporting you and helping you find your soulmate without any prejudice. CONNECT with us! Record and send the voice messages. Enjoy what life brings you. TapeMe is a unique tool that will show you how the voice can be the key to the soul🔑. There's nothing easier than turning on the app, handing out likes, and start making friends and having fun!</div>
                <div className="text-middle">TOGETHER let's change the way how we date, TOGETHER let's connect. Just download the app on Google play or the App Store, create a profile, put on your headphones and turn on RECORDING. Then it's up to you! Show the world what you've got 🌍!</div>
                <div className="row input-row">
                    <a className="join-button" href="/"><div>Connect with us</div></a>
                </div>
            </div>
        </section>
    )
}

export default About